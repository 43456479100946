@import "./_varibale.scss";

@mixin flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin inline-flex($justify-content: center, $align-items: center) {
  display: inline-flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin coverScreen($position: absolute, $backColor: transparent) {
  position: $position;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $backColor;
}

@mixin positionCenter($position: absolute) {
  position: $position;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@mixin positionLeft($position: absolute, $left: $common-wd) {
  position: $position;
  top: 50%;
  left: $left;
  transform: translate3d(0, -50%, 0);
}

@mixin positionRight($position: absolute, $right: $common-wd) {
  position: $position;
  top: 50%;
  right: $right;
  transform: translate3d(0, -50%, 0);
}

@mixin wh($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin clearFloat() {
  &:after {
    clear: both;
    content: "";
    display: block;
  }
}

@mixin text-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  word-wrap: normal;
  text-overflow: ellipsis;
}

@mixin font_size($default: 16px, $isImportant: false) {
  /*通过该函数设置字体颜色，后期方便统一管理；*/
  $important: "";
  @if ($isImportant==true) {
    $important: " !important";
  }
  font-size: $default #{$important}; //默认时的color
  [data-size="small"] & {
    font-size: $default+2px #{$important};
  }
  [data-size="middle"] & {
    font-size: $default+4px #{$important};
  }
  [data-size="large"] & {
    font-size: $default+6px #{$important};
  }
}

@mixin text {
  color: $text;
  font-weight: normal;
}

@mixin title {
  color: $text;
  font-weight: bold;
}

@mixin ios_safe_area($attr, $position: top, $defaultNum: 0) {
  @if $attr==top {
    top: rem(88);
  }
  @else if $attr==padding-top {
    padding-top: rem(40);
  }
}

@supports (top: constant(safe-area-inset-top)) {
  @mixin ios_safe_area($attr, $position: top, $defaultNum: 0) {
    $safeAreName: safe-area-inset-top;
    @if $position==bottom {
      $safeAreName: safe-area-inset-bottom;
    }
    @else if $position==left {
      $safeAreName: safe-area-inset-left;
    }
    @else if $position==right {
      $safeAreName: safe-area-inset-right;
    }
    #{$attr}: $defaultNum;
    @if $defaultNum==0 {
      #{$attr}: unquote("constant(#{$safeAreName})");
    }
    @else {
      #{$attr}: unquote("calc(#{$defaultNum} + constant(#{$safeAreName}))");
    }
  }
}

@supports (bottom: env(safe-area-inset-bottom)) {
  @mixin ios_safe_area($attr, $position: top, $defaultNum: 0) {
    $safeAreName: safe-area-inset-top;
    @if $position==bottom {
      $safeAreName: safe-area-inset-bottom;
    }
    @else if $position==left {
      $safeAreName: safe-area-inset-left;
    }
    @else if $position==right {
      $safeAreName: safe-area-inset-right;
    }
    #{$attr}: $defaultNum;
    @if $defaultNum==0 {
      #{$attr}: unquote("env(#{$safeAreName})");
    }
    @else {
      #{$attr}: unquote("calc(#{$defaultNum} + env(#{$safeAreName}))");
    }
  }
}
@mixin top-line($c: #c7c7c7) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 1px;
  border-top: 1px solid $c;
  color: $c;
  transform-origin: 0 0;
  transform: scaleY(0.5);
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    transform: scaleY(0.333333333);
  }
}

@mixin bottom-line($c: #e6e6e6) {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid $c;
  color: $c;
  transform-origin: 0 100%;
  transform: scaleY(0.5);
  display: inline-block!important;
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    transform: scaleY(0.333333333);
  }
}

@mixin left-line($c: #c7c7c7) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-left: 1px solid $c;
  color: $c;
  transform-origin: 0 0;
  transform: scaleX(0.5);
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    transform: scaleX(0.333333333);
  }
}

@mixin right-line($c: #c7c7c7) {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-right: 1px solid $c;
  color: $c;
  transform-origin: 100% 0;
  transform: scaleX(0.5);
  @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
    transform: scaleX(0.333333333);
  }
}

@mixin line($c: #c7c7c7, $border-radius: 0) {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: 200%;
  border: 1px solid $c;
  color: $c;
  transform-origin: left top;
  @if ($border-radius !=0) {
    border-radius: $border-radius * 2;
  }
  transform: scale(0.5);
  @media (-webkit-min-device-pixel-ratio: 3),
  (min-device-pixel-ratio: 3) {
    @if ($border-radius !=0) {
      border-radius: $border-radius * 3;
    }
    width: 300%;
    height: 300%;
    transform: scale(0.333333333);
  }
}

// 一像素上边框
@mixin border-top-1px($color: #c7c7c7) {
  position: relative;
  &:before {
    @include top-line($color);
  }
}

// 一像素下边框
@mixin border-bottom-1px($color: #c7c7c7) {
  position: relative;
  &:after {
    @include bottom-line($color);
  }
}

// 一像素上下边框
@mixin border-top-bottom-1px($color: #c7c7c7) {
  position: relative;
  &:before {
    @include top-line();
  }
  &:after {
    @include bottom-line();
  }
}

// 一像素左边框
@mixin border-left-1px($color: #c7c7c7) {
  position: relative;
  &:before {
    @include left-line();
  }
}

// 一像素右边框
@mixin border-right-1px($color: #c7c7c7) {
  position: relative;
  &:before {
    @include right-line();
  }
}

// 一像素边框
@mixin border-1px($color: #c7c7c7, $position: relative, $border-radius: 0) {
  position: $position;
  &:after {
    @include line($color, $border-radius);
  }
}

@mixin animate($animateName: slideInUp, $time: 0.4s, $mode: both) {
  animation-name: $animateName;
  animation-duration: $time;
  animation-fill-mode: $mode;
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@mixin animateZoomIn($animateName: zoomIn, $time: 0.4s, $mode: both) {
  animation-name: $animateName;
  animation-duration: $time;
  animation-fill-mode: $mode;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}