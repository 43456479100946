@media only screen and (max-width: 1024px) {
    .main-header {
        height: 55px!important;
        padding: 0 16px;
        box-sizing: border-box;
        .container {
            justify-content: space-between!important;
            .logo {
                width: 32px;
            }
        }
        .header-nav {
            display: none!important;
        }
        .mb-menu {
            width: 20px;
            height: 14px;
            display: block!important;
            position: relative;
            >div {
                width: 100%;
                transition: 300ms;
                margin: 0 auto;
                border-radius: 1px;
                height: 2px;
                background-color: #fff;
                position: absolute;
                &:nth-child(2) {
                    top: 7px;
                }
                &:nth-child(3) {
                    top: 14px;
                }
            }
            &.active {
                >div {
                    &:nth-child(1) {
                        transform: rotate(45deg);
                        transform-origin: left 1px;
                    } 
                    &:nth-child(3) {
                        transform: rotate(-45deg) translate(3px, -7px);
                    } 
                    &:nth-child(2) {
                        display: none;
                    } 
                }
            }
        }
        .mb-nav {
            top: 55px;
            transition: 300ms;
            position: fixed;
            width: 100%;
            padding-top: 10vh;
            height: calc(100% - 55px);
            z-index: 98;
            display: block!important;
            background: rgba(0, 0, 0, 0.6);
            li {
                width: 100%;
                padding: 0 24px;
                box-sizing: border-box;
                margin: 0 auto;
                margin-top: 24px;
                text-align: right;
                &.active {
                    color: #469dff;
                    font-weight: 700;
                }
            }
        }
    }

}