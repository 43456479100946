@import "~@helper";
@import "./mb.scss";
.main-header {
    width: 100%;
    height: 68px;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    @include flex();
    .container {
        @include flex();
    }
    .logo {
        width: 42px;
        img {
            display: block;
            width: 100%;
        }
    }
    .header-nav {
        flex: 1;
        position: relative;
        @include flex(flex-end, center);
        li {
            width: 112px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            margin-left: 24px;
            z-index: 1;
            position: relative;
            cursor: pointer;
        }
        .active {
            width: 112px;
            border-radius: 4px;
            height: 32px;
            position: absolute;
            right: 0;
            transition: all 0.3s;
            z-index: 0;
            top: 0;
            background: #469dff;
        }
    }
    .mb-menu{
        display: none;
    }
    .mb-nav {
        display: none;
    }
}