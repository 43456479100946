@import '../helper/varibale'; //全局样式

.container {
    width: 1200px;
    margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
    .container {
        width: 100vw;
    }
}