.scroll-bar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 13px;
  }
  &::-webkit-scrollbar-thumb {          
    background-color: #eeeeee;
    border-radius: 5px;
  }
  // &::-webkit-scrollbar-track-piece {           
  //   background-color: #dedede;
  //   border-radius: 5px;
  // }
}